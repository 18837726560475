<template>
  <div>
    <Modal :options="{width:'40vw', type: 'secondary'}" @close="$emit('close')">
      <div class="title">Agregar comprobante</div>
      <div class="body">

        <div class="row form-group">
          <label for="buscar_archivo" class="col-form-label col-sm-3">Tipo</label>
          <div class="col-sm-9">
            <select v-model="tipo_comprobante" name="tipo_comprobante" id="tipo_comprobante" class="form-control">
              <option value="na">Seleccionar tipo de comprobante</option>
              <option value="nota">Nota de compra</option>
              <option value="factura">Factura</option>
            </select>
          </div>
        </div>

        <template v-if="tipo_comprobante == 'nota'">
        <div class="row form-group">
          <label for="buscar_archivo" class="col-form-label col-sm-3">Nota de compra</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="form-control">{{ archivo_nota_nombre }}</div>
              <div class="input-group-append">
                <button id="buscar_archivo" name="buscar_archivo" class="btn btn-info" @click="$refs.archivo_nota.click()">Buscar</button>
              </div>
            </div>
          </div>
          <input type="file" name="archivo_nota" id="archivo_nota" ref="archivo_nota"  @change="() => {
            archivo_nota_nombre = $refs.archivo_nota.files.length > 0 ? $refs.archivo_nota.files[0].name : '';
          }" />
        </div>

        <div class="row form-group">
          <label for="concepto" class="col-form-label col-sm-3">Concepto</label>
          <div class="col-sm-9">
            <input v-model="comprobante.descripcion" type="text" name="concepto" id="concepto" class="form-control">
            <small>Indica el concepto, debe coincidir con el registrado en el documento.</small>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Monto</label>
          <div class="col-sm-9">
            <IC v-model="comprobante.monto" id="comprobante_monto" name="comprobante_monto" label="$" :formats="['moneyFormat']" />
            <small>Indica el monto del concepto, debe coincidir con el registrado en el documento.</small>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-9 offset-sm-3 text-right">
            <button class="btn btn-info" @click="agregar_concepto_manual">Agregar concepto</button>
          </div>
        </div>
        </template>

        <template v-else-if="tipo_comprobante == 'factura'">
        <div class="row form-group">
          <label for="buscar_archivo" class="col-form-label col-sm-3">Factura XML</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="form-control">
                {{ archivo_xml_nombre }}
              </div>
              <div class="input-group-append">
                <button id="buscar_archivo" name="buscar_archivo" class="btn btn-info" @click="$refs.archivo_xml.click()">Buscar</button>
              </div>
            </div>
          </div>
          <input type="file" name="archivo_xml" id="archivo_xml" ref="archivo_xml" accept=".xml" @change="leer_factura" />
        </div>

        <div class="row form-group">
          <label for="buscar_archivo" class="col-form-label col-sm-3">Factura PDF</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="form-control">
                {{ archivo_pdf_nombre }}
              </div>
              <div class="input-group-append">
                <button id="buscar_archivo" name="buscar_archivo" class="btn btn-info" @click="$refs.archivo_pdf.click()">Buscar</button>
              </div>
            </div>
          </div>
          <input type="file" name="archivo_pdf" id="archivo_pdf" ref="archivo_pdf" accept=".pdf" @change="() => {
              archivo_pdf_nombre = $refs.archivo_pdf.files.length > 0 ? $refs.archivo_pdf.files[0].name : '';
            }">
        </div>
        </template>

        <template v-if="conceptos_factura.length > 0">
        <div class="row form-group">
          <label for="conceptos" class="col-form-label col-sm-3">Conceptos en factura</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <select v-model="concepto" name="conceptos" id="conceptos" class="form-control">
                <option value="na">Selecciona el concepto indicado</option>
                <option v-for="(concepto, index) in conceptos_factura" :key="index" :value="$helper.toJson(concepto)">{{ concepto.Descripcion }}</option>
              </select>
              <div class="input-group-append">
                <button class="btn btn-secondary" @click="agregar_concepto">Agregar</button>
              </div>
            </div>
          </div>
        </div>
        </template>

        <template v-if="tipo_comprobante != 'na'">
        <div class="row">
          <label class="col-form-label col-sm-3">Conceptos a agregar</label>
          <div class="col-sm-9">
            <table class="conceptos_comprobante">
              <thead>
                <tr>
                  <th>Concepto</th>
                  <th>Monto</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tmp_concepto, index) in conceptos" :key="index">
                  <td>{{ tmp_concepto.descripcion }}</td>
                  <td>${{ $helper.moneyFormat(tmp_concepto.monto) }}</td>
                  <td><i class="fa-solid fa-trash"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <label class="col-form-label col-sm-3">Monto total del comprobante</label>
          <div class="col-sm-9">
            <div class="form-control">
              ${{ $helper.moneyFormat(monto_total) }}
            </div>
          </div>
        </div>
        </template>

      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_comprobante">Agregar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import IC from '@/components/Form/InputCompuesto';

import api from '@/apps/constelacion/api/tesoreria'

export default {
  components: {
    Modal, IC
  }
  ,props: {
    viatico: {
      type: Object,
      required: true,
      default() {
        return {
          id: null
        }
      }
    }
  }
  ,data() {
    return {
      tipo_comprobante: 'na',
      archivo_nota_nombre: null,
      archivo_xml_nombre: null,
      archivo_pdf_nombre: null,
      conceptos_factura: [],
      concepto: 'na',
      comprobante: {
        descripcion: null,
        monto: 0
      },
      conceptos: []
    }
  }
  ,methods: {
    async leer_factura() {
      try {
        if (this.$refs.archivo_xml.files.length == 0)
          return;

        let formData = new FormData();
        formData.append('factura', this.$refs.archivo_xml.files[0]);

        this.conceptos_factura = (await api.leer_factura(formData)).data;
        this.$log.info('res', this.conceptos_factura);

        if (this.conceptos_factura.length == 0) {
          this.$refs.archivo_xml.value = null;
          return this.$helper.showMessage('Conceptos en factura','La factura no tiene conceptos','info','alert');
        }

        this.archivo_xml_nombre = this.$refs.archivo_xml.files[0].name;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    agregar_concepto() {
      let concepto = this.$helper.fromJson(this.concepto);

      // let monto_total = this.monto_total + parseFloat(concepto.Importe);

      // if (monto_total > this.monto_viatico) {
      //   let restante = (this.monto_viatico - monto_total) * -1;
      //   return this.$helper.showMessage('Error', 'El monto total sobrepasa el monto del viatico por $'+this.$helper.moneyFormat(restante),'error','alert');
      // }

      this.conceptos.push({
        descripcion: concepto.Descripcion,
        monto: parseFloat(concepto.Importe)
      });
    }
    ,agregar_concepto_manual() {
      if (this.comprobante.descripcion == null || this.comprobante.monto == 0)
        return this.$helper.showMessage('Error','Debes definir la descripción y el monto.','error', 'alert');
      
      this.$log.info('comprobante', this.comprobante);
      this.comprobante.monto = parseFloat(this.comprobante.monto);

      let comprobante = this.$helper.clone(this.comprobante);

      this.conceptos.push(comprobante);
      this.comprobante.descripcion = null;
      this.comprobante.monto = 0;
    }
    ,async agregar_comprobante() {
      try {
        if (this.tipo_comprobante == 'na')
          return this.$helper.showMessage('Error','Debes seleccionar el tipo de comprobante','error','alert');

        if (this.tipo_comprobante == 'nota') {
          if (this.$refs.archivo_nota.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar el documento de la nota de compra','error','alert');
        }else {
          if (this.$refs.archivo_xml.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar el XML de la factura','error','alert');
          this.$log.info('tipo: ',this.$refs.archivo_xml.files[0].type);
          if (this.$refs.archivo_pdf.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar el PDF de la factura','error','alert');
        }

        if (this.conceptos.length == 0)
          return this.$helper.showMessage('Error','Debes agregar al menos un concepto al comprobante','error','alert');

        let payload = new FormData();
        payload.append('tipo', this.tipo_comprobante);
        payload.append('monto', this.monto_total);
        
        let i=0;
        this.conceptos.forEach(concepto => {
          payload.append('conceptos['+i+'][concepto]', concepto.descripcion);
          payload.append('conceptos['+i+'][monto]', concepto.monto);
          i++;
        })

        if (this.tipo_comprobante == 'nota') {
          payload.append('archivos[]', this.$refs.archivo_nota.files[0]);
        }else {
          payload.append('archivos[]', this.$refs.archivo_xml.files[0]);
          payload.append('archivos[]', this.$refs.archivo_pdf.files[0]);
        }

        let res = (await api.crear_comprobante(this.viatico.id, payload)).data;
        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
  ,computed: {
    monto_total() {
      let total = 0;

      this.conceptos.forEach(concepto => {
        total += concepto.monto;
      })

      return total;
    }
  }
  ,watch: {
    tipo_comprobante(val) {
      this.archivo_nota_nombre = null;
      this.archivo_xml_nombre = null;
      this.archivo_pdf_nombre = null;
      this.conceptos_factura = [];
      this.concepto = 'na';
      this.comprobante = {
        descripcion: null,
        monto: 0
      };
      this.conceptos = [];
    }
  }
}
</script>

<style lang="scss" scoped>
small {
  color: #919191;
}

input[type="file"] {
  display: none;
}

.conceptos_comprobante {
  width: 100%;

  thead {
    tr {
      background-color: #6d6d6d;

      th {
        padding: 5px 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 10px;
      }
      
      td:last-child {
        text-align: center;
      }
    }

    tr:nth-child(2n+0) {
      background-color: #ececec;
    }
  }
}
</style>