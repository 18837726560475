<template>
  <div>
    <Modal :options="{width: '80vw', close: true}" @close="$emit('close')">
      <div class="title">Viaticos a pagar</div>
      <div class="body">
        <DataGrid :config="dataGrid_config" :data="viaticos" :select="seleccionar" @actions="dataGridActions" @ready="buscar_campos">
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-warning mr-2" @click="obtener_viaticos">Recargar</button>
              <button class="btn btn-primary mr-2" @click="pre_pago">Pagar</button>
            </div>
          </div>
        </DataGrid>
      </div>
    </Modal>

    <Modal v-if="modal_confirmar" :options="{width: '30vw', type: 'warning'}">
      <div class="title">Pagar {{ seleccionadas.length }} viaticos</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8 offset-sm-2 text-center">Estas por pagar  <strong>{{ seleccionadas.length }}</strong> viaticos por <strong>${{ $helper.moneyFormat(monto_a_pagar) }}</strong><br/> ¿Deseas continuar?</div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-success mr-2" @click="pagar_viaticos">Pagar</button>
            <button class="btn btn-danger" @click="cancelar_pagos">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Info v-if="modal_viatico" origen="viatico" :viatico_id="viatico.id" :catalogo="catalogo" @close="modal_viatico=false" />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import DataGrid from '@/components/DataGridV2';

import Info from './Info';
import config from '@/apps/tesoreria/configuracion/viaticos'

import apiCatalogos from '@/apps/tesoreria/api/catalogos';
import api from '@/apps/tesoreria/api/viaticos';

export default {
  components: {
    Modal, DataGrid, Info
  }
  ,props: {
    catalogo: {
      type: Array,
      required: true
    }
  }
  ,data() {
    return {
      dataGrid_config: {
        name: 'viaticos_pagar',
        // select_items: false,
        cols: {
          identificador: 'Viatico',
          monto: 'Monto',
          monto_pago_extra: 'Monto pago extra',
          cuenta_contable: {
            text: 'Cuenta contable'
            ,ordeable: false
          },
          estatus_texto: 'Estatus',
          opciones: {
            text: 'Opciones'
            ,ordeable: false
          },
        },
        paginator: {
          pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
        },
        mutators: {
          monto(val, row, vue) {
            return '$'+vue.$helper.moneyFormat(val);
          }
          ,monto_pago_extra(val, row, vue) {
            return '$'+vue.$helper.moneyFormat(val);
          }
          ,cuenta_contable(val, row, vue) {
            let select = '<select class="form-control input_cuentas_contables" id="cuenta_contable_'+row.id+'" name="cuenta_contable_'+row.id+'" disabled>'
            +'<option value="na">Seleccionar cuenta contable</option>';

            row.cuentas_contables.forEach(cc => {
              select += '<option value="'+cc.valor+'">'+cc.nombre+'</option>';
            })

            select += '</select>';

            return select;
          }
          ,opciones(val, row, vue) {
            return '<button data-viatico="'+row.id+'" class="btn btn-secondary btn-viatico-detalle mr-2">Detalles</button><button data-viatico="'+row.id+'" class="btn btn-info btn-viatico-pagodetalle">Pago externo</button>';
          }
        }
      },
      options: {
        page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
      }
      ,seleccionadas: []
      ,seleccionar: false
      ,viaticos: []
      ,viatico: {
        id: null
      }
      ,cuentas_contables: []
      ,monto_a_pagar: 0
      ,modal_confirmar: false
      ,modal_viatico: false
    }
  }
  ,mounted() {
    // this.obtener_cuentas_contables();
  }
  ,methods: {
    async dataGridActions(tipo, data) {
      if (tipo == 'options') {
        this.options = data;
        this.obtener_cuentas_contables();
      }else {
        for await (let sel of this.seleccionadas) {
          let cuenta_contable = document.querySelector('#cuenta_contable_'+sel.id);
          cuenta_contable.disabled = true;
        }

        for await (let sel of data) {
          let cuenta_contable = document.querySelector('#cuenta_contable_'+sel.id);
          cuenta_contable.disabled = false;
        }

        this.seleccionadas = data;
        this.seleccionar = true;
      }
    },
    async obtener_cuentas_contables() {
      try {
        if (this.cuentas_contables.length > 0)
          return this.obtener_viaticos();

        let res = (await apiCatalogos.buscar_catalogo('cuentas_contables')).data;
        this.$log.info('res', res);

        this.cuentas_contables = res.valores;
        this.obtener_viaticos();
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async obtener_viaticos() {
      try {
        let res = (await api.obtener_viaticos_por_pagar(this.options)).data;
        this.viaticos = res.data;

        for(let i=0; i<this.viaticos.length; i++) {
          // this.viaticos[i].saldo_pendiente = this.viaticos[i].monto - this.pagos_hechos(this.viaticos[i]);
          this.viaticos[i].estatus_texto = this.catalogo.find(op => op.valor == this.viaticos[i].estatus).nombre;
          this.viaticos[i].cuentas_contables = this.cuentas_contables;
        }

        this.dataGrid_config.paginator.pagina_actual = res.data.current_page;
        this.dataGrid_config.paginator.total_registros = res.data.total;
        this.dataGrid_config.paginator.registros_por_pagina = parseInt(res.data.per_page);
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,pagos_hechos(viatico) {
      let pagado = 0;

      viatico.pagos.forEach(pago => {
        pagado += parseFloat(pago.monto);
      });

      this.$log.info('saldo', pagado);

      return pagado;
    }
    ,buscar_campos() {
      let a_pagar = document.querySelectorAll('.input_a_pagar');

      a_pagar.forEach(input => {
        input.removeEventListener('focus',this.a_pagar_limpiar);
        input.addEventListener('focus',this.a_pagar_limpiar);

        input.removeEventListener('focusout',this.a_pagar_formato);
        input.addEventListener('focusout',this.a_pagar_formato);
      })

      let btn_detalles = document.querySelectorAll('.btn-viatico-detalle');
      btn_detalles.forEach(btn => {
        btn.removeEventListener('click', this.detalle_viatico);
        btn.addEventListener('click', this.detalle_viatico);
      });
    }
    ,a_pagar_limpiar(e) {
      let valor = e.target.value;
      valor = valor.replace(/[^0-9.]/i,'');
      e.target.value = valor;
    }
    ,a_pagar_formato(e) {
      let valor = e.target.value;
      e.target.value = this.$helper.moneyFormat(valor);
    }
    ,detalle_viatico(e) {
      this.viatico.id = parseInt(e.target.dataset.viatico);
      this.modal_viatico = true;
    }
    ,pre_pago() {
      if (this.seleccionadas.length == 0)
        return this.$helper.showMessage('Error','Debes seleccionar al menos un viatico a pagar','error','alert');
      
      let error = false;
      this.monto_a_pagar = 0;
      for(let i=0; i<this.seleccionadas.length; i++) {
        let valor = this.estatus_validos(this.seleccionadas[i].estatus, ['ESTATUS_PROCESO_PAGO','ESTATUS_ERROR_PAGO']) ? parseFloat(this.seleccionadas[i].monto) : parseFloat(this.seleccionadas[i].monto_pago_extra);

        if (valor <= 0) {
          this.$helper.showMessage('Error','En el viatico '+(i+1)+' el monto no puede ser menor o igual que 0','error','alert');
          i=this.seleccionadas.length;
          error = true;
        }

        let cuenta_contable = document.querySelector('#cuenta_contable_'+this.seleccionadas[i].id).value;
        if (cuenta_contable == 'na') {
          this.$helper.showMessage('Error','En el viatico '+(i+1)+' debes seleccionar la cuenta contable','error','alert');
          i=this.seleccionadas.length;
          error = true;
        }

        this.$log.info('estatus', this.seleccionadas[i].estatus);
        // if (!this.estatus_validos(this.seleccionadas[i].estatus, ['ESTATUS_PROCESO_PAGO','ESTATUS_PAGO_MONTO_EXTRA','ESTATUS_ERROR_PAGO'])) {
        //   this.$helper.showMessage('Error','En el viatico '+(i+1)+' debe estar en estatus en EN PROCESO DE PAGO, PAGO MONTO EXTRA o ERROR PAGO','error','alert');
        //   i=this.seleccionadas.length;
        //   error = true;
        // }

        this.monto_a_pagar += valor;
      }

      if (error)
        return;

      this.modal_confirmar = true;
    }
    ,async pagar_viaticos() {
      try {
        let payload = []

        this.seleccionadas.forEach(viatico => {
          let cuenta_contable = document.querySelector('#cuenta_contable_'+viatico.id).value;

          payload.push({
            id: viatico.id,
            monto: this.estatus_validos(viatico.estatus, ['ESTATUS_PROCESO_PAGO','ESTATUS_ERROR_PAGO']) ? parseFloat(viatico.monto) : parseFloat(viatico.monto_pago_extra),
            cuenta_contable: cuenta_contable,
            tipo_pago: viatico.estatus == config.viaticos.constantes.ESTATUS_PROCESO_PAGO ? 'despliegue' : 'extra'
          });
        })

        let res = (await api.pagar_viaticos({pagos: payload})).data;
        this.$log.info('res', res);

        this.modal_confirmar = false;
        this.obtener_cuentas_contables();
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,cancelar_pagos() {
      this.modal_confirmar = false;
      this.seleccionar = false;
    }
    ,estatus_validos(valor, estatus) {
      return config.viaticos.estatus_validos(valor, estatus);
    }
  }
  ,computed: {
    total_pagar() {
      let total = 0;
     
      this.seleccionadas.forEach(viatico => {
        total += parseFloat(viatico.monto);
      })

      return '$'+this.$helper.moneyFormat(total);
    }
  }
}
</script>