<template>
  <div>
    <div class="row">
      <h1 class="col-sm-12 text-right my-3">Viaticos</h1>
    </div>

    <DataGrid :config="datagrid_config" :data="viaticos" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
      <div class="row">
        <div class="col-sm-9">
          <button class="btn btn-warning mr-2" @click="obtener_viaticos">Recargar</button>
          <button class="btn btn-success" @click="modal_pagar=true">Pagar viaticos</button>
        </div>
        <div class="col-sm-3 text-right">
          <form @submit.prevent="filtrar_viaticos">
            <div class="input-group mb-3">
              <select v-model="filtrado" name="filtrar_viaticos" id="filtrar_viaticos" class="form-control" @change=" filtrar_viaticos">
                <option value="all">Mostrar todos</option>
                <option v-for="op in catalogo_filtrado" :key="op.id" :value="op.valor">{{ op.nombre }}</option>
              </select>
              <div class="input-group-append">
                <button class="btn btn-secondary">Filtrar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DataGrid>

    <Info v-if="modal_viatico" :viatico_id="viatico.id" :catalogo="catalogo" @close="modal_viatico=false" @update="obtener_viaticos" />
    <Pagar v-if="modal_pagar" :catalogo="catalogo" @close="modal_pagar=false" @update="obtener_viaticos" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV2';

import Info from '@/apps/tesoreria/pages/Tesoreria/Viatico/Info';
import Pagar from '@/apps/tesoreria/pages/Tesoreria/Viatico/Pagar';

import apiCatalogo from '@/apps/tesoreria/api/catalogos'
import api from '@/apps/tesoreria/api/viaticos'
import { all } from 'q';

export default {
  name: 'app',
  components: { 
    DataGrid, Info, Pagar
  },
  data() {
    return {
      datagrid_config: {
        name: 'viaticos',
        select_items: false,
        cols: {
          'autorizador.area': 'Área',
          nombre: 'Usuario',
          identificador: 'Viatico',
          monto: 'Monto',
          estatus: 'Estatus',
          created_at: 'Creción',
          opciones: 'Opciones'
        },
        paginator: {
          pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
        },
        mutators: {
          monto(val, row, vue) {
            return '$'+vue.$helper.moneyFormat(val);
          },
          created_at(val, row, vue) {
            return vue.$moment(val).format('ddd DD MMM YYYY HH:mm');
          },
          opciones(val, row, vue) {
            return '<button class="btn btn-primary btn-viaticos" id="viatico_'+row.id+'" data-viatico="'+vue.$helper.toJson(row)+'">Revisar</button>';
          }
        }
      },
      options: {
        page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
      }
      ,catalogo: []
      ,viaticos: []
      ,seleccionadas: []
      ,seleccionar: false
      ,viatico: {
        id: null,
        autorizador_id: null,
        account_id: null,
        nombre: null,
        identificador: null,
        descripcion: null,
        monto: null,
        estatus: null
      }
      ,filtrado: 'all'
      ,modal_viatico: false
      ,modal_pagar: false
    }
  },
  mounted() {
    
  }
  ,methods: {
    dataGridActions: function(tipo, data) {
      if (tipo == 'options') {
        this.options = data;
        this.obtener_catalogo();
      }else {
        this.seleccionadas = data;
        this.seleccionar = true;
      }
    },
    async obtener_catalogo() {
      try {
        if (this.catalogo.length > 0)
          return this.obtener_viaticos();

        let res = (await apiCatalogo.buscar_catalogo('estatus_viaticos')).data;
        this.$log.info('catalogo', res);
        this.catalogo = res.valores;

        this.obtener_viaticos();
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async obtener_viaticos() {
      try {
        let res = (await api.obtener_viaticos(this.options)).data;
        this.viaticos = res.data;

        for(let i=0; i<this.viaticos.length; i++) {
          this.viaticos[i].estatus = this.catalogo.find(op => op.valor == this.viaticos[i].estatus).nombre;
        }

        this.datagrid_config.paginator.pagina_actual = res.data.current_page;
        this.datagrid_config.paginator.total_registros = res.data.total;
        this.datagrid_config.paginator.registros_por_pagina = parseInt(res.data.per_page);
      }catch(e) {
        this.$log.info('error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_botones() {
      let btns = document.querySelectorAll('.btn-viaticos');

      btns.forEach(btn => {
        btn.removeEventListener('click',this.revisar_viatico);
        btn.addEventListener('click', this.revisar_viatico);
      })
    },
    revisar_viatico(e) {
      let btn = e.target;
      this.viatico = this.$helper.fromJson(btn.dataset.viatico);
      this.modal_viatico = true;
    },
    filtrar_viaticos() {
      switch(this.filtrado) {
        case 'all':
            this.options.filters = [];
            break
        default:
          this.options.filters = [];
          this.options.filters.push({columna: 'estatus',operador: 'equal_to', valor1: this.filtrado});
      }

      // this.obtener_viaticos();
    }
  }
  ,computed: {
    catalogo_filtrado() {
      let opciones = [];

      for(let i=0; i<this.catalogo.length; i++) {
        if (this.catalogo[i].codigo != 'proceso_pago' && this.catalogo[i].codigo != 'error_pago')
          opciones.push(this.catalogo[i]);
      }

      opciones.sort((a, b) => {
        return parseInt(a.valor) > parseInt(b.valor) ? 1 : -1;
      })

      return opciones;
    }
  }
}
</script>